//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { needTagsView } from '@/settings';
import { Navbar, Sidebar, TagsView } from './components/index';
import ResizeMixin from './mixin/ResizeHandler'; // 切换侧边菜单栏
import EmptyRoute from './EmptyRoute.vue';
import Breadcrumb from './components/Breadcrumb/index.vue';
import Hamburger from './components/Hamburger/index.vue';
export default {
  name: 'Layout',
  components: {
    EmptyRoute: EmptyRoute,
    Navbar: Navbar,
    Sidebar: Sidebar,
    TagsView: TagsView,
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger },

  data: function data() {
    return {
      needTagsView: needTagsView };

  },
  mixins: [ResizeMixin],
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    },
    device: function device() {
      return this.$store.state.app.device;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        // hideSidebar: false,
        // openSidebar: true,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile' };

    } },

  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false });
    },
    refreshMain: function refreshMain() {
      this.$refs.main.reload();
    } } };